'use client'
import { AffirmInfo } from 'components/AffirmInfo'
import { CoverCTABlackFridayEarlyAccess, CoverCTABlackFridayEarlyAccessKlarna } from 'components/CoverCTA'
import { Button } from 'components/WebEv/Buttons'
import { PriceManager } from 'prices'
import { Currency } from 'prices/types'
import React from 'react'
import { useCurrency, useGetUpToDiscountNumber, usePod4Prices } from 'stores/prices'
import { useStore } from 'stores/StoreProvider'
import { useCurrentRegion } from 'stores/settings'
import { SiteSettings } from 'config/types'
import { RegionShort } from 'utils/internationalization'
export type ErrorProps = {
	statusCode: number
	title?: string

	currency: Currency
	getUpToDiscountNumber: number
	coverMaxMonthsAtZeroInterest: number
	pod4MonthlyPriceFormatted: string
	settings: SiteSettings
	currentRegion: RegionShort
}

export interface S {
	didMount: boolean
}

export const Error404PageConnected = () => {
	const currency = useCurrency()
	const getUpToDiscountNumber = useGetUpToDiscountNumber()
	const pod4Prices = usePod4Prices()
	const settings = useStore((state) => state.settings.settings)
	const currentRegion = useCurrentRegion()

	return (
		<Error404Page
			statusCode={404}
			currency={currency}
			getUpToDiscountNumber={getUpToDiscountNumber}
			coverMaxMonthsAtZeroInterest={pod4Prices.maxMonthsAtZeroInterest}
			pod4MonthlyPriceFormatted={pod4Prices.monthlyPriceFormatted}
			settings={settings}
			currentRegion={currentRegion}
		/>
	)
}

/**
 * `Error` component used for handling errors.
 */
class Error404Page<P> extends React.Component<P & ErrorProps, S> {
	static displayName = 'ErrorPage'

	constructor(props) {
		super(props)
		this.state = {
			didMount: false,
		}
	}

	componentDidMount() {
		this.setState({ didMount: true })
	}

	render() {
		const title = 'This page does not exist'

		const showAffirm = PriceManager.currencyHasAffirm(this.props.currency)
		const showKlarna = PriceManager.currencyHasKlarna(this.props.currency)
		const showFinancing = showAffirm || showKlarna

		const baseDiscountAmount = this.props.getUpToDiscountNumber

		return (
			<>
				<div
					style={styles.error}
					id="container-404"
				>
					<div style={styles.textContainer}>
						{this.state.didMount && <style dangerouslySetInnerHTML={{ __html: 'body { margin: 0; opacity: 1; }' }} />}
						<h1 style={styles.h1}>{404}</h1>
						<div style={styles.desc}>
							<h2 style={styles.h2}>{title}.</h2>
						</div>
					</div>
					<Button.Primary
						id={'go-to-homepage'}
						size={'large'}
						href="https://www.eightsleep.com/"
					>
						GO TO HOMEPAGE
					</Button.Primary>
					<style
						dangerouslySetInnerHTML={{
							__html: `
            div#__next > div > div.headroom.headroom-wrapper + div {
                height: 100% !important;
            }
            `,
						}}
					/>
				</div>
				{showFinancing ? (
					<section className={'bg-mid-grey inner-paddings-y'}>
						{showAffirm ? (
							<AffirmInfo
								showImage
								monthlyPrice={this.props.pod4MonthlyPriceFormatted}
								numMonths={this.props.coverMaxMonthsAtZeroInterest}
								currency={this.props.currency}
								saleName={this.props.settings.saleName}
							/>
						) : (
							<CoverCTABlackFridayEarlyAccessKlarna className="bg-white" />
						)}
					</section>
				) : this.props.currentRegion !== 'au' ? (
					<section className={'bg-mid-grey inner-paddings-y'}>
						<CoverCTABlackFridayEarlyAccess
							className="bg-white"
							heading="Conquer the season, rest assured"
						/>
					</section>
				) : (
					<></>
				)}
			</>
		)
	}
}

export default Error404Page

const styles: { [k: string]: React.CSSProperties } = {
	error: {
		marginTop: 30,
		marginBottom: 30,
		color: '#000',
		background: '#fff',
		fontFamily: '-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif',
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
	},
	textContainer: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '2rem',
	},
	desc: {
		display: 'inline-block',
		textAlign: 'left',
		lineHeight: '49px',
		height: '49px',
		verticalAlign: 'middle',
	},

	h1: {
		display: 'inline-block',
		borderRight: '1px solid rgba(0, 0, 0,.3)',
		margin: 0,
		marginRight: '20px',
		padding: '10px 23px 10px 0',
		fontSize: '24px',
		fontWeight: 500,
		verticalAlign: 'top',
	},

	h2: {
		fontSize: '14px',
		fontWeight: 'normal',
		lineHeight: 'inherit',
		margin: 0,
		padding: 0,
	},
}
