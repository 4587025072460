'use client'

import { ClientOnly } from 'components/ClientOnly'
import { FooterConnected } from 'components/Footer_LEGACY'
import PageWrapper from 'components/PageWrapper'
import { pageViewedPages, PageViewedType } from 'constants/events'
import { datadogReportError } from 'events/datadog'
import { safeError } from 'events/error.utils'
import { trackPageViewed } from 'events/index'
import dynamic from 'next/dynamic'
import { FC, ReactNode, Suspense, useCallback, useEffect, useState } from 'react'
import { HeaderConnected } from 'sections/Header/Header'
import { useStore } from 'stores/StoreProvider'

interface MainProps {
	children?: ReactNode
	classes?: { [name: string]: string }
	className?: string
	inverted?: boolean
	hideHeader?: boolean
	hideCart?: boolean
}

const refreshAffirm = (): void => {
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		window.affirm?.ui?.refresh?.()
	} catch (e: unknown) {
		datadogReportError(safeError(e), 'Affirm Refresh')
	}
}

const CartLazy = dynamic(() => import('sections/Cart').then((module) => module.Cart), {
	ssr: false,
})

export const MainAppLayout: FC<MainProps> = (props) => {
	const { children, inverted, hideHeader, hideCart } = props

	const [timeoutIds, setTimeoutIds] = useState<ReturnType<typeof setTimeout>[]>([])
	const [timeoutPages, setTimeoutPages] = useState<PageViewedType[]>([])
	const [pageViewStartTime, setPageViewStartTime] = useState<number | null>(null)

	const timerStoreInit = useStore((state) => state.timer.registerEvents)
	const setShowInterstitial = useStore((state) => state.cart.setShowInterstitial)
	const toggleCartOpen = useStore((state) => state.cart.toggleCartOpen)
	const navSetRoute = useStore((state) => state.nav.setRoute)

	const stopPageViewTimer = useCallback(() => {
		for (let i = 0; i < timeoutIds.length; i++) {
			const sessionPageTime = sessionStorage.getItem(`PAGE_VIEWED_${timeoutPages[i]}`)
			if (sessionPageTime === null) {
				continue
			}

			const pageTime = JSON.parse(sessionPageTime) as number
			if (pageTime <= 0 || pageViewStartTime === null) {
				continue
			}

			const diffTime = Date.now() - pageViewStartTime
			const newTime = pageTime - diffTime
			sessionStorage.setItem(`PAGE_VIEWED_${timeoutPages[i]}`, JSON.stringify(newTime))
			clearTimeout(timeoutIds[i])
		}
	}, [timeoutIds, timeoutPages, pageViewStartTime])

	const initPageViewedTimer = useCallback(() => {
		let path = document.location.pathname
		if (path.substr(-1) === '/') {
			path = path.substring(0, path.length - 1)
		}

		const timeoutIds: ReturnType<typeof setTimeout>[] = []
		const timeoutPages: PageViewedType[] = []
		const pageViewStartTime = Date.now()
		for (const page of pageViewedPages) {
			if (!page.pages.includes(path)) {
				continue
			}
			const pageTimeLeftStr = sessionStorage.getItem(`PAGE_VIEWED_${page.type}`)
			const timeLeft = pageTimeLeftStr !== null ? (JSON.parse(pageTimeLeftStr) as number) : page.timeMs
			if (timeLeft <= 0) {
				continue
			}
			sessionStorage.setItem(`PAGE_VIEWED_${page.type}`, JSON.stringify(timeLeft))
			const timeoutId = setTimeout(() => {
				trackPageViewed(page.eventName, page.type)
				sessionStorage.setItem(`PAGE_VIEWED_${page.type}`, '-1')
			}, timeLeft)
			timeoutIds.push(timeoutId)
			timeoutPages.push(page.type)
		}

		setTimeoutIds(timeoutIds)
		setTimeoutPages(timeoutPages)
		setPageViewStartTime(pageViewStartTime)
	}, [])

	useEffect(() => {
		const hash = window.location.hash
		timerStoreInit()

		setShowInterstitial(false)

		if (hash === '#cart') {
			toggleCartOpen()
		}

		initPageViewedTimer()
		window.addEventListener('focus', initPageViewedTimer)
		window.addEventListener('blur', stopPageViewTimer)

		//trackPageView()
		navSetRoute()

		document.body.style.setProperty('--nav-translate', `0px`)

		refreshAffirm()
	}, [navSetRoute, setShowInterstitial, toggleCartOpen, timerStoreInit])

	useEffect(() => {
		return () => {
			stopPageViewTimer()

			const nextElement = document.getElementById('__next')
			if (nextElement) {
				nextElement.classList.remove('__next')
			}

			window.removeEventListener('focus', initPageViewedTimer)
			window.removeEventListener('blur', stopPageViewTimer)
		}
	}, [])

	const _inverted = inverted ?? false
	const _hideHeader = hideHeader ?? false
	const _hideCart = hideCart ?? false

	return (
		<div style={{ backgroundColor: _inverted ? 'white' : 'black', color: _inverted ? 'black' : 'white' }}>
			{!_hideHeader && <HeaderConnected />}
			<PageWrapper>{children}</PageWrapper>
			{!_hideCart && (
				<Suspense fallback={null}>
					<CartLazy />
				</Suspense>
			)}
			<Suspense fallback={null}>
				<FooterConnected />
			</Suspense>
			<ClientOnly>
				<div
					style={{ position: 'fixed', top: 0 }}
					id={'client'}
					data-testid={'client'}
				/>
			</ClientOnly>
		</div>
	)
}
