import React, { FC } from 'react'
import styles from './CoverCTA.module.scss'
import { Button } from 'components/WebEv/Buttons'
import { Img } from 'components/basic/Img'
import { useDiscountFormatted } from 'stores/prices'
import { useStore } from 'stores/StoreProvider'
export interface CoverCTAProps {
	marker?: string | React.ReactNode
	heading?: string
	subheading?: string
	sublabel?: string
	href?: string
	ctaLabel?: string
	footer?: string
	className?: string
}

export const CoverCTA: FC<CoverCTAProps> = (props) => {
	const marker = props.marker || ''
	const href = props.href || '/product/pod-cover/'
	const heading = props.heading || ''
	const subheading = props.subheading || ''
	const sublabel = props.sublabel || ''
	const ctaLabel = props.ctaLabel || `Shop now`
	const footer = props.footer || 'Limited time offer. Ends soon.'

	return (
		<div className={`${styles.inner} ${props.className}`}>
			<div className={styles.mobile_heading_container}>
				<h2 className={styles.mobile_heading}>{heading}</h2>
			</div>

			<div className={styles.image_placeholder}>
				<Img
					src={'https://eightsleep.imgix.net/pod_shadow_cropped.png?v=1683226186'}
					alt={'The Pod Cover'}
					dprHeight={600}
				/>
			</div>
			<div className={styles.text}>
				<div className={styles.pill}>{marker}</div>
				<h2>{heading}</h2>
				<h3>{subheading}</h3>
				<h5>{sublabel}</h5>
				<div className={styles.content}>
					<Button.Primary
						id={'cover-lp-shop-now'}
						className={styles.cta}
						href={href}
					>
						{ctaLabel}
					</Button.Primary>
					<p className={styles.offer_ends}>{footer}</p>
				</div>
			</div>
		</div>
	)
}

export const CoverCTABlackFridayEarlyAccess = (props: CoverCTAProps) => {
	const discountFormatted = useDiscountFormatted()
	const settings = useStore((state) => state.settings.settings)
	return (
		<CoverCTA
			subheading={`${discountFormatted} off`}
			sublabel={'The Pod'}
			heading={props.heading ?? settings.compareHeading}
			marker={settings.saleName}
			className={props.className}
			ctaLabel={props.ctaLabel}
		/>
	)
}

export const CoverCTABlackFridayEarlyAccessKlarna = (props: CoverCTAProps) => {
	const settings = useStore((state) => state.settings.settings)
	const marker = props.marker ?? settings.saleName
	const href = props.href || '/product/pod-cover/'
	const ctaLabel = props.ctaLabel || `Shop now`
	const footer = props.footer || 'Limited time offer. Ends soon.'
	const heading = props.heading ?? 'Invest in your rest and pay over time'

	return (
		<div className={`${styles.inner} ${props.className}`}>
			<div className={styles.mobile_heading_container}>
				<h2 className={styles.mobile_heading}>{heading}</h2>
			</div>

			<div className={styles.image_placeholder}>
				<Img
					src={'https://eightsleep.imgix.net/pod_shadow_cropped.png?v=1683226186'}
					alt={'The Pod Cover'}
					dprHeight={600}
				/>
			</div>
			<div className={`${styles.text} ${styles.text_center}`}>
				<div className={styles.pill}>{marker}</div>
				<h2>{heading}</h2>
				<h5 className={styles.grayish}>
					3 interest-free payments with no fees. <br />
					Pay later with{' '}
					<img
						src="https://eightsleep.imgix.net/text.svg?v=1699448650"
						alt="Klarna logo"
					/>
				</h5>
				<div className={styles.content}>
					<Button.Primary
						id={'cover-lp-shop-now'}
						className={styles.cta}
						href={href}
					>
						{ctaLabel}
					</Button.Primary>
					<p className={styles.offer_ends}>{footer}</p>
				</div>
			</div>
		</div>
	)
}
