import { Img } from 'components/basic/Img'
import { FC, ReactNode } from 'react'
import styles from './AffirmInfo.module.scss'
import { Button } from 'components/WebEv/Buttons'
import cx from 'classnames'
import { Currency } from 'prices/types'

interface AffirmInfoProps {
	showImage?: boolean
	className?: string
	grey?: boolean

	href?: string

	ctaText?: string
	monthlyPrice: string
	numMonths: number
	currency: Currency
	heading?: string
	marker?: string | ReactNode
	saleName: string
}

export const AffirmInfo: FC<AffirmInfoProps> = (props) => {
	const items = getItems(props.monthlyPrice || '$60', props.currency)
	return (
		<div className={cx(styles.container, { [styles.grey]: props.grey })}>
			{props.showImage && (
				<div className={styles.desktop_image_container}>
					<Img
						src={'https://eightsleep.imgix.net/pod-cover-modern-large.webp?v=1683289654'}
						dprHeight={800}
						alt={'The Pod Cover'}
					/>
				</div>
			)}
			<div>
				<div className={styles.header_container}>
					{props.marker ?? <p>{props.saleName}</p>}
					<h2>{props.heading || 'Invest in your rest and pay over time'}</h2>
				</div>
				{props.showImage && (
					<div className={styles.mobile_image_container}>
						<Img
							src={'https://eightsleep.imgix.net/pod-cover-modern-large.webp?v=1683289654'}
							dprHeight={600}
							alt={'The Pod Cover'}
						/>
					</div>
				)}

				<div className={styles.content_container}>
					<ul className={styles.item_list}>
						{items.map((item, index) => (
							<li key={index}>
								<Item
									{...item}
									showDivider={index !== items.length - 1}
								/>
							</li>
						))}
					</ul>

					<div className={styles.bottom_info}>
						<Button.Primary
							href={props.href || '/product/pod-cover'}
							className={styles.cta_button}
							id={'affirm-info-cta-button'}
						>
							{props.ctaText || `Shop now`}
						</Button.Primary>
						<p className={styles.terms}>
							{props.currency === 'CAD' ? (
								<span>
									Finance monthly with Affirm
									<br />
								</span>
							) : (
								<span>
									Pay {props.monthlyPrice} over {props.numMonths} months with Affirm
									<br />
								</span>
							)}
							<span>
								Terms apply ·{' '}
								<a
									className={cx('affirm-site-modal')}
									data-provider="Affirm"
									data-track="['click','mouseover'], 'Financing', 'Pre qualify button for Affirm'"
									tabIndex={0}
									aria-label="Pre qualify button for affirm"
								>
									Pre-qualify now
								</a>
							</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

const getItems = (monthlyPrice: string, currency: Currency): ItemProps[] => {
	// TODO does not work for krona
	const priceSplit = monthlyPrice.split(/(\d+)/)
	const currencySymbol = priceSplit[0].trim()
	const amount = priceSplit[1].trim()

	return [
		{
			topLine: 'Less than',
			stat: (
				<>
					<sup>$</sup>2
				</>
			),
			bottomLine: 'per night',
		},
		{
			topLine: 'As low as',
			stat: (
				<>
					<sup>{currencySymbol}</sup>
					{amount}
				</>
			),
			bottomLine: 'per month',
		},
		{
			topLine: 'Starts at',
			stat: (
				<>
					{currency === 'CAD' ? 10 : 0}
					<sup>%</sup>
				</>
			),
			bottomLine: 'APR',
		},
	]
}

interface ItemProps {
	topLine: string
	stat: ReactNode
	bottomLine: string
	showDivider?: boolean
}

const Item: FC<ItemProps> = (props) => {
	return (
		<div className={cx(styles.item, { [styles.divider]: props.showDivider })}>
			<p className={'vh'}>
				{props.topLine} {props.stat} {props.bottomLine}
			</p>
			<p aria-hidden>{props.topLine}</p>
			<strong aria-hidden>{props.stat}</strong>
			<p aria-hidden>{props.bottomLine}</p>
		</div>
	)
}
